<template>
  <page-view title="电子发票作废申请" left-arrow :nav-border="false" custom-class="zfsqPage" white-bg>
    <van-form :show-error="true" :show-error-message="false" :scroll-to-error="true" :validate-first="true"
              @submit="onSubmit" label-width="auto" input-align="right">
      <van-cell-group>
      <zzjg-select
          v-model="zfsqForm.jgbmList"
          :label="'组织机构'"
          label-width="150"
          :sjzzbm="sjzzbm"
          :required="true"
          inputAlign="right"
          @confirm="zzjgConfirm"/>
      <hsdy-select
          ref="hsdySelect"
          v-model="zfsqForm.dybmList"
          :label="'核算单元'"
          label-width="150"
          :jgbm="zfsqForm.jgbm"
          :required="true"
          inputAlign="right"
          @confirm="hsdyConfirm"/>
        <van-field
            type="text"
            required
            readonly
            v-model="zfsqForm.yhbh"
            label="用户编号:"
            placeholder="用户编号"
        />
        <van-field
            type="text"
            required
            readonly
            v-model="zfsqForm.yhmc"
            label="户名:"
            placeholder="户名"
        />
        <van-field
            type="text"
            required
            readonly
            v-model="zfsqForm.zwyf"
            label="账务月份:"
            placeholder="账务月份"
        />
        <van-field
            type="text"
            required
            readonly
            v-model="zfsqForm.fphm"
            label="发票号码:"
            placeholder="发票号码"
        />
        <van-field
            type="text"
            required
            readonly
            v-model="'￥'+zfsqForm.hjje"
            label="发票金额:"
            placeholder="发票金额"
        />
        <van-field
            type="text"
            required
            readonly
            v-model="'￥'+zfsqForm.hjse"
            label="发票税额:"
            placeholder="发票税额"
        />
        <van-field
            type="textarea"
            required
            v-model="zfsqForm.zfyy"
            label="作废原因:"
            placeholder="请输入作废原因"
            rows="3"
            autosize
            inputAlign="right"
        />
      </van-cell-group>
      <div style="margin: 16px;display: flex;" class="text-center">
        <van-button type="primary" native-type="submit" class="mr-10" size="large" :disabled="submitStatus">提交</van-button>
      </div>
    </van-form>
  </page-view>
</template>

<script>
import ZzjgSelect from '@/components/zzjg-select/index.vue';
import HsdySelect from '@/components/hsdy-select/index.vue';
import GzdFjUploader from '@/components/GzdFjUploader';
import {zwDzfpjl_selectiveGetOne} from "@/api/psdmsdfzw/sfglschema/ZwDzfpjlAPI";
import {dzfpService_invoiceZfsq} from "@/api/psdmsdfzw/service/DzfpServiceAPI";
import {mapGetters} from "vuex";

export default {
  name: "zfsq",
  components: {
    ZzjgSelect,
    HsdySelect,
    GzdFjUploader,
  },
  computed: {
    ...mapGetters(['czyh']),
  },
  data() {
    return {
      sjzzbm:'1R',
      zfsqForm:{
        jgbm:this.$store.state.user.jgbm,
        dybm:this.$store.state.user.dybm,
        zfyy:"",
        fphm:"",
        zwyf:"",
        yhbh:"",
        hjje:"0",
        hjse:"0"
      },
      currentDzfpxx:{},
      submitStatus:false
    }
  },
  methods:{
    async getDzfpDetail(){
      let res = await zwDzfpjl_selectiveGetOne({
        yhbh:this.$route.query.yhbh,
        zwyf:this.$route.query.zwyf,
        fphm:this.$route.query.fphm,
      });
      if(res.code===200){
        this.currentDzfpxx = res.content;
        this.zfsqForm = {
          ...this.zfsqForm,
          ...this.currentDzfpxx,
          yhmc:this.$route.query.yhmc,
          jgbmList:this.currentDzfpxx.jgbm.split('').map((item,index)=>{
            return this.currentDzfpxx.jgbm.split('').splice(0,index+1).join('');
          }),
        };
        this.sjzzbm = this.currentDzfpxx.jgbm.indexOf('1R')<=-1?'1':'1R';
      }else{
        await this.$dialog.alert({
          title: "提示",
          message: "获取电子发票信息失败"
        });
      }
    },
    zzjgConfirm(value,options){
      if(value && value.length){
        this.zfsqForm.jgbm = value[value.length-1];
        this.zfsqForm.dybm = '';
        this.zfsqForm.dybmList = [];
      }
    },
    hsdyConfirm(value,options){
      let lastChildren = options[options.length-1].children;
      if(lastChildren && lastChildren.length>0){
        this.$dialog.alert({
          title: "提示",
          message: "请选择核算单元精确到供电组!"
        });
        return;
      }
      if(value && value.length){
        this.zfsqForm.dybm = value[value.length-1];
      }
    },
    async onSubmit(values){
      if(!this.zfsqForm.jgbm){
        await this.$dialog.alert({
          title: "提示",
          message: "请选择组织机构"
        });
        return;
      }
      if(!this.zfsqForm.dybm){
        await this.$dialog.alert({
          title: "提示",
          message: "请选择核算单元"
        });
        return;
      }
      if(!this.zfsqForm.zfyy){
        await this.$dialog.alert({
          title: "提示",
          message: "请输入作废原因"
        });
        return;
      }
      this.submitStatus = true;
      let loading = this.$toast.loading({
        message: "正在提交发票作废申请",
        forbidClick: true,
        duration: 0
      })
      this.zfsqForm.czry = this.czyh;
      let res = await dzfpService_invoiceZfsq(this.zfsqForm);
      loading.clear();
      if(res.code!=200 && res.code!=9999){
        this.submitStatus = false;
      }
      await this.$dialog.alert({
        title: "提示",
        message: res.content?res.content.message:res.message
      });

    }
  },
  async mounted() {
    let loading = this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0
    })
    await this.getDzfpDetail();
    loading.clear();
  }
}
</script>

<style scoped>

</style>