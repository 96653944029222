// WaGen___HASHKEY__e027c2a_2024-08-22 11:32:31 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
标准CRUD方法：添加记录
*/
export function zwDzfpjl_add(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/add',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwDzfpjl
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：yhbh : Stringzwyf : Integerfpdm : Stringfphm : String
*/
export function zwDzfpjl_update(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/update',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwDzfpjl
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：yhbh : Stringzwyf : Integerfpdm : Stringfphm : String
*/
export function zwDzfpjl_remove(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/remove',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwDzfpjl
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function zwDzfpjl_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/selectiveDelete',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwDzfpjl
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function zwDzfpjl_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/selectiveUpdate',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwDzfpjl
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function zwDzfpjl_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/selectiveAdd',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwDzfpjl
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：yhbh : Stringzwyf : Integerfpdm : Stringfphm : String
*/
export function zwDzfpjl_fetch(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/fetch',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwDzfpjl
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function zwDzfpjl_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/fetchAll',
		method : 'post',
		headers : {
		},
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function zwDzfpjl_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/selectiveGet',
		method : 'post',
		headers : {
		},
		data : {
			param : entity, //ZwDzfpjl
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function zwDzfpjl_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/selectiveGetOne',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwDzfpjl
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function zwDzfpjl_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/selectiveCount',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwDzfpjl
		}
	})
}

/* ---
未提供注释
*/
export function zwDzfpjl_updateDzfpjlZf(yhbh,zwyf,fpdm,fphm, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/updateDzfpjlZf',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				yhbh : yhbh, //String
				zwyf : zwyf, //Integer
				fpdm : fpdm, //String
				fphm : fphm //String
			}
		}
	})
}

/* ---
多记录插入
*/
export function zwDzfpjl_insertList(list, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/insertList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ZwDzfpjl>
		}
	})
}

/* ---
多记录更新,需要的主键字段：yhbh : Stringzwyf : Integerfpdm : Stringfphm : String
*/
export function zwDzfpjl_updateList(list, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/updateList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ZwDzfpjl>
		}
	})
}

/* ---
多记录删除,需要的主键字段：yhbh : Stringzwyf : Integerfpdm : Stringfphm : String
*/
export function zwDzfpjl_deleteList(list, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwDzfpjl/deleteList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ZwDzfpjl>
		}
	})
}

